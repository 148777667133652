import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { authConfig } from './auth-config';
import { environment } from '@environments/environment';
import { WINDOW_PROVIDER } from './services/providers/window.provider';
import {
  AlertComponent,
  ButtonComponent,
  CardComponent,
  corePlatformUiTranslateLoader,
  CorePlatformUiTranslateModule,
  FilterResultsComponent,
  FiltersComponent,
  FilterSingleSelectComponent,
  FilterSingleSelectOptionComponent,
  GLSTemplateDirective,
  IconButtonComponent,
  SearchInputComponent,
  TextInputComponent,
  ToastComponent,
  ToggleSwitchMultipleComponent,
  ToggleSwitchMultipleOptionComponent,
} from '@gls/platform-ui';
import { translationsLoader } from './translations/translations.loader';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { TokenInterceptor } from './interceptors/token-interceptor/token-interceptor.service';
import { DefaultErrorHandler } from './services/errors/default-error-handler.service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    CorePlatformUiTranslateModule.forRoot({
      loaders: [corePlatformUiTranslateLoader, translationsLoader],
      defaultLocale: 'en',
      supportedLocales: ['en'],
      fallbackLocales: [],
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: authConfig.clientId,
          authority: authConfig.authority,
          redirectUri: environment.redirectUri,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: authConfig.scopes,
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', authConfig.scopes],
        ]),
      },
    ),
    AppLayoutComponent,
    FilterResultsComponent,
    FilterSingleSelectComponent,
    FilterSingleSelectOptionComponent,
    FiltersComponent,
    ReactiveFormsModule,
    SearchInputComponent,
    AlertComponent,
    TextInputComponent,
    GLSTemplateDirective,
    CardComponent,
    ButtonComponent,
    IconButtonComponent,
    ToggleSwitchMultipleComponent,
    ToggleSwitchMultipleOptionComponent,
    ToastComponent,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: DefaultErrorHandler },
    MsalGuard,
    WINDOW_PROVIDER,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  exports: [],
})
export class AppModule {}
