import { z } from 'zod';

export const PageSchema = <T extends z.ZodTypeAny>(resourceSchema: T) =>
  z.object({
    schemas: z.object({
      schemas: z.array(z.string()),
    }),
    startIndex: z.number(),
    itemsPerPage: z.number(),
    totalResults: z.number(),
    resources: z.array(resourceSchema),
  });

// Type inference for the generic page schema
export type PageType<T extends z.ZodTypeAny> = z.infer<
  ReturnType<typeof PageSchema<T>>
>;

export const ResourceSchema = z.object({
  id: z.string(),
  externalId: z.string().optional(),
  meta: z.object({
    resourceType: z.string(),
    created: z.string(),
    lastModified: z.string(),
    location: z.string(),
  }),
});
