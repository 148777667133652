<svg
  width="420"
  height="256"
  viewBox="0 0 420 256"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_2679_26305)">
    <path
      d="M329.165 39.9863C329.165 39.9863 319.947 41.0262 319.351 55.3111C318.854 67.216 317.841 73.8498 322.684 76.3329C324.413 77.2204 326.446 77.2966 328.296 76.6915L348.051 70.1922C348.051 70.1922 348.346 48.767 341.506 43.5586C334.666 38.3502 329.165 39.9863 329.165 39.9863Z"
      fill="#36344E"
    />
    <path
      d="M340.055 72.8189L330.057 76.1088V61.7073H339.128L340.055 72.8189Z"
      fill="#FAAFB2"
    />
    <path
      d="M309.492 154.566C308.95 157.865 306.751 160.232 304.582 159.851C302.41 159.47 301.093 156.485 301.635 153.186C301.832 151.864 302.356 150.613 303.158 149.542L305.635 135.593L312.377 137.005L309.219 150.604C309.635 151.89 309.729 153.249 309.492 154.566Z"
      fill="#FAAFB2"
    />
    <path
      d="M332.382 247.318H323.02L317.586 197.251H332.064L332.382 247.318Z"
      fill="#FAAFB2"
    />
    <path
      d="M313.9 255.363C312.914 255.363 312.037 255.341 311.378 255.278C308.892 255.05 306.518 253.212 305.326 252.141C304.793 251.661 304.619 250.89 304.896 250.227C305.098 249.752 305.497 249.398 305.994 249.254L312.565 247.376L323.208 240.191L323.329 240.406C323.374 240.487 324.418 242.369 324.767 243.642C324.901 244.126 324.866 244.53 324.664 244.844C324.525 245.059 324.328 245.184 324.171 245.256C324.364 245.458 324.969 245.866 326.828 246.161C329.547 246.592 330.111 243.777 330.138 243.66L330.156 243.566L330.236 243.512C331.526 242.679 332.324 242.302 332.597 242.383C332.767 242.432 333.054 242.522 333.824 250.186C333.901 250.429 334.443 252.19 334.075 253.88C333.677 255.718 325.663 255.086 324.059 254.938C324.014 254.942 318.017 255.368 313.904 255.368H313.9V255.363Z"
      fill="#36344E"
    />
    <path
      d="M353.538 247.318H344.172L338.743 197.251H353.216L353.538 247.318Z"
      fill="#FAAFB2"
    />
    <path
      d="M335.056 255.363C334.071 255.363 333.193 255.341 332.534 255.278C330.048 255.05 327.674 253.212 326.483 252.141C325.95 251.661 325.775 250.89 326.053 250.227C326.254 249.752 326.653 249.398 327.15 249.254L333.721 247.376L344.364 240.191L344.485 240.406C344.53 240.487 345.574 242.369 345.923 243.642C346.058 244.126 346.022 244.53 345.82 244.844C345.681 245.059 345.484 245.184 345.327 245.256C345.52 245.458 346.125 245.866 347.984 246.161C350.703 246.592 351.267 243.777 351.294 243.66L351.312 243.566L351.393 243.512C352.687 242.679 353.48 242.302 353.753 242.383C353.923 242.432 354.21 242.522 354.981 250.186C355.057 250.429 355.599 252.19 355.231 253.88C354.833 255.718 346.819 255.086 345.216 254.938C345.171 254.942 339.173 255.368 335.061 255.368H335.056V255.363Z"
      fill="#36344E"
    />
    <path
      d="M356.23 124.347H310.733L327.087 70.4881H345.229L356.23 124.347Z"
      fill="#DFDFE0"
    />
    <path
      d="M329.949 70.4926C329.949 70.4926 318.648 70.1967 317.461 73.173C316.274 76.1492 302.293 146.973 302.293 146.973H311.512L329.949 70.497V70.4926Z"
      fill="#DFDFE0"
    />
    <path
      d="M331.293 65.692C336.795 65.692 341.255 61.229 341.255 55.7235C341.255 50.218 336.795 45.7549 331.293 45.7549C325.791 45.7549 321.331 50.218 321.331 55.7235C321.331 61.229 325.791 65.692 331.293 65.692Z"
      fill="#FAAFB2"
    />
    <path
      d="M328.57 43.7066C328.57 43.7066 323.087 65.9834 329.184 76.3957L320.597 74.5714C320.597 74.5714 317.268 50.1924 323.961 45.7594L328.57 43.7066Z"
      fill="#36344E"
    />
    <path
      d="M346.774 124.347H316.914L300.578 191.442C300.278 194.275 302.074 196.919 304.825 197.659C313.788 200.061 335.298 203.463 367.85 193.983C370.981 193.074 372.759 189.766 371.778 186.65L346.774 124.347Z"
      fill="#36344E"
    />
    <path
      d="M334.702 70.4881L343.988 70.0802C348.01 70.385 351.648 72.5723 353.802 75.9833C360.365 86.3643 372.643 108.242 361.682 111.241C347.231 115.19 340.23 93.2042 340.23 93.2042L334.702 70.4881Z"
      fill="#DFDFE0"
    />
    <path
      d="M331.517 77.0053C329.609 74.2621 329.542 71.0304 331.36 69.7888C333.184 68.5473 336.207 69.7709 338.115 72.5141C338.895 73.5988 339.388 74.8628 339.558 76.1895L347.491 87.9241L341.672 91.613L334.469 79.6543C333.273 79.0223 332.261 78.1124 331.517 77.0053Z"
      fill="#FAAFB2"
    />
    <path
      d="M336.212 86.4942L342.765 80.4611L355.724 92.5319L343.675 100.775L336.212 86.4942Z"
      fill="#DFDFE0"
    />
    <path
      d="M15.1096 56.7589H220.831C228.849 56.7589 235.349 63.2627 235.349 71.2859C235.349 79.3092 228.849 85.8129 220.831 85.8129H15.1096C7.09142 85.8129 0.591797 79.3092 0.591797 71.2859C0.591797 63.2627 7.09142 56.7589 15.1096 56.7589Z"
      fill="#DEEBFE"
    />
    <path
      d="M91.6626 255.467C91.6626 255.762 91.9 256 92.1956 256H418.875C419.17 256 419.408 255.762 419.408 255.467C419.408 255.171 419.17 254.933 418.875 254.933H92.1956C91.9 254.933 91.6626 255.171 91.6626 255.467Z"
      fill="#484565"
    />
    <path
      d="M190.797 42.1109H233.508C234.529 42.1109 235.353 42.9356 235.353 43.9531C235.353 44.9705 234.529 45.7953 233.508 45.7953H190.797C189.775 45.7953 188.951 44.9705 188.951 43.9531C188.951 42.9356 189.775 42.1109 190.797 42.1109Z"
      fill="#DFDFE0"
    />
    <path
      d="M2.43731 0H45.1485C46.1698 0 46.994 0.824737 46.994 1.84221C46.994 2.85968 46.1698 3.68442 45.1485 3.68442H2.43731C1.41601 3.6889 0.591797 2.86417 0.591797 1.84221C0.591797 0.824737 1.41601 0 2.43731 0Z"
      fill="#DFDFE0"
    />
    <path
      d="M2.43731 16.0958H127.803C128.824 16.0958 129.648 16.9205 129.648 17.9425C129.648 18.96 128.824 19.7847 127.803 19.7847H2.43731C1.41601 19.7847 0.591797 18.96 0.591797 17.9425C0.591797 16.925 1.41601 16.0958 2.43731 16.0958Z"
      fill="#DFDFE0"
    />
    <path
      d="M105.307 116.933C105.307 126.597 113.137 134.432 122.795 134.432C125.72 134.432 128.475 133.715 130.894 132.446L147.839 159.34C148.878 160.985 151.096 161.397 152.655 160.232C154.11 159.143 154.424 157.09 153.362 155.611L134.723 129.726C138.141 126.53 140.282 121.98 140.282 116.933C140.282 107.27 132.452 99.4345 122.795 99.4345C113.137 99.4345 105.307 107.27 105.307 116.933ZM107.708 116.933C107.708 108.596 114.463 101.833 122.799 101.833C131.135 101.833 137.89 108.592 137.89 116.933C137.89 125.27 131.135 132.034 122.799 132.034C114.468 132.034 107.708 125.275 107.708 116.933Z"
      fill="#484662"
    />
  </g>
  <defs>
    <clipPath id="clip0_2679_26305">
      <rect
        width="418.816"
        height="256"
        fill="white"
        transform="translate(0.591797)"
      />
    </clipPath>
  </defs>
</svg>
<gls-alert [color]="'info'" style="width: 440px">
  <span alert-text>
    <b>Enter an email to search</b>
    <br />
    Make sure the email provided is valid.
  </span>
</gls-alert>
