import { Component } from '@angular/core';
import { AlertComponent } from '@gls/platform-ui';

@Component({
  selector: 'app-message-search-something',
  standalone: true,
  imports: [AlertComponent],
  templateUrl: './message-search-something.component.html',
  styleUrl: './message-search-something.component.scss',
})
export class MessageSearchSomethingComponent {}
