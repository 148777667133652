/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes(environment.baseUrl)) {
      return next.handle(req);
    }

    if (this.msalService.instance.getAllAccounts().length === 0) {
      return next.handle(req);
    }

    const idToken = this.msalService.instance.getAllAccounts()[0].idToken;
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${idToken}`),
    });

    return next.handle(authReq);
  }
}
