import { z } from 'zod';
import { WelcomeEmailSchema } from './scim-schemas/scim_schema__welcome-email';
import { GroupAEBCustomsSchema } from './scim-schemas/scim_schema__group-aeb-customs';
import { SCIMSchema } from './scim-schemas/scim-schema';
import {
  messageCoreSCIMSchema,
  messageGroupAEBCustomsSchema,
  messageWelcomeEmailSchema,
  validateCoreSCIMSchema,
  validateGroupAEBCustomsSchema,
  validateWelcomeEmailSchema,
} from './human-user-refinements';
import { CommonAttributesSchema } from './scim-schemas/scim_schema__common-attributes';
import { ResourceSchema } from '../http/page';

export const HumanUserSchema = CommonAttributesSchema.extend({
  schemas: z.array(SCIMSchema),
  'urn:ietf:params:scim:schemas:extension:welcome_email:2.0:User':
    WelcomeEmailSchema.optional(),
  'urn:ietf:params:scim:schemas:extension:group_aeb_customs:2.0:User':
    GroupAEBCustomsSchema.optional(),
});

HumanUserSchema.refine(validateCoreSCIMSchema, messageCoreSCIMSchema)
  .refine(validateWelcomeEmailSchema, messageWelcomeEmailSchema)
  .refine(validateGroupAEBCustomsSchema, messageGroupAEBCustomsSchema);

export type HumanUserType = z.infer<typeof HumanUserSchema>;

export const HumanUserGETSchema = ResourceSchema.merge(HumanUserSchema).extend({
  password: z.string().optional(),
});

export const HumanUserPOSTSchema = HumanUserGETSchema;

export type HumanUserGETType = z.infer<typeof HumanUserGETSchema>;

export const HumanUserPUTSchema = HumanUserGETSchema;
export type HumanUserPUTType = z.infer<typeof HumanUserPUTSchema>;
