import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AvatarComponent,
  DropdownComponent,
  DropdownItemComponent,
  DropdownItemLabelComponent,
  GLSTemplateDirective,
  IconButtonComponent,
  IconComponent,
  NavigationComponent,
  NavigationContentComponent,
  NavigationFooterComponent,
  NavigationHeaderComponent,
  NavigationMenuItemComponent,
  NavigationMenuListComponent,
  PopperDirective,
} from '@gls/platform-ui';
import { Router, RouterLink } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';
import { ProfileAzureAD } from '../../../../models/azure-ad/graph-endpoint.model';
import { AzureAdService } from '../../../../services/azure-ad/azure-ad.service';

@Component({
  selector: 'app-panel-left',
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    NavigationComponent,
    NavigationHeaderComponent,
    NavigationContentComponent,
    NavigationFooterComponent,
    IconButtonComponent,
    DropdownComponent,
    DropdownItemLabelComponent,
    DropdownItemComponent,
    PopperDirective,
    NavigationMenuListComponent,
    GLSTemplateDirective,
    NavigationMenuItemComponent,
    IconComponent,
    RouterLink,
  ],
  templateUrl: './panel-left.component.html',
  styleUrls: ['./panel-left.component.scss'],
})
export class PanelLeftComponent implements OnInit {
  profile$!: Observable<ProfileAzureAD>;
  profileImage$!: Observable<SafeUrl>;

  constructor(
    private router: Router,
    private msalService: MsalService,
    private azureAdService: AzureAdService,
  ) {}

  ngOnInit() {
    this.profile$ = this.azureAdService.getProfile();
    this.profileImage$ = this.azureAdService.getProfileImage();
  }

  logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.redirectUri,
    });
  }

  isSelected(url: string) {
    return this.router.url === url;
  }
}
