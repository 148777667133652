import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { ModalService } from '../../shared/services/modal/modal.service';
import { ModalErrorComponent } from '../../shared/components/modal-error/modal-error.component';
import { ModalErrorClientComponent } from '../../shared/components/modal-error-client/modal-error-client.component';

@Injectable({
  providedIn: 'root',
})
export class DefaultErrorHandler implements ErrorHandler {
  constructor(
    private zone: NgZone,
    private injector: Injector,
  ) {}

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      this.handleServerSideError(error);
    } else {
      this.handleClientSideError(error);
    }
  }

  private handleClientSideError(error: any): void {
    const modalService = this.injector.get(ModalService);
    console.error(error);

    this.zone.run(() => {
      modalService.showComponent(ModalErrorClientComponent, {
        hasBackdrop: true,
        data: { error: error },
      });
    });
  }

  private handleServerSideError(error: HttpErrorResponse): void {
    const modalService = this.injector.get(ModalService);

    this.zone.run(() => {
      modalService.showComponent(ModalErrorComponent, {
        hasBackdrop: true,
        data: { error: error },
      });
    });
  }
}
