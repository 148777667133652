import { z } from 'zod';

export const AddressSchema = z.object({
  streetAddress: z.string(),
  locality: z.string(),
  region: z.string(),
  postalCode: z.string(),
  country: z.string().refine((val) => /^[A-Z]{2}$/.test(val), {
    message: 'Country must be exactly 2 uppercase letters (e.g., US, CA).',
  }),
  type: z.literal('default'),
});

export type AddressType = z.infer<typeof AddressSchema>;
