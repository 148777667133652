<app-layout>
  <div class="containerr">
    <div class="filters-example">
      <form
        class="form-filters"
        [formGroup]="searchForm"
        (ngSubmit)="onSubmitSearch()"
      >
        <gls-filters>
          <gls-search-input
            formControlName="searchTerm"
            placeholder="username@email.com"
          ></gls-search-input>
          <ng-container buttonGroup>
            <gls-filter-single-select
              formControlName="searchBy"
              [buttonLabel]="searchForm.value['searchBy']"
            >
              <gls-filter-single-select-option
                ngValue="Human Account"
                [label]="'Human Account'"
              >
              </gls-filter-single-select-option>
              <gls-filter-single-select-option
                ngValue="Technical Account"
                [label]="'Technical Account'"
              >
              </gls-filter-single-select-option>
            </gls-filter-single-select>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <gls-button
              type="submit"
              [loading]="asyncAction.is('*', 'performing')"
              variant="primary-exceptional"
            >
              Search
            </gls-button>
          </ng-container>
        </gls-filters>
      </form>
      <gls-filter-results
        [formGroup]="searchForm"
        [enableClearAll]="true"
      ></gls-filter-results>
    </div>

    <div *ngIf="whatToShow === 'message-search-something'">
      <app-message-search-something />
    </div>
    <div *ngIf="whatToShow === 'message-search-not-found'">
      <app-message-search-not-found />
    </div>

    <div *ngIf="whatToShow === 'form'">
      <br />
      <app-form-common-attributes></app-form-common-attributes>
      <br />
      <app-form-group-aeb-customs
        [schemasForms]="schemasForms"
      ></app-form-group-aeb-customs>

      <div *ngIf="hasFormChanges | async" class="update-button-container">
        <gls-button
          (click)="onUpdate()"
          [loading]="asyncAction.is('updating', 'performing')"
        >
          Update
        </gls-button>
      </div>
    </div>
  </div>
</app-layout>
