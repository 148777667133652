import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { HumanAccountService } from 'src/app/services/api/human-account.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  CorePlatformUiFilterFormControl,
  FilterResultsComponent,
  FiltersComponent,
  FilterSingleSelectComponent,
  FilterSingleSelectOptionComponent,
  SearchInputComponent,
  ToastService,
} from '@gls/platform-ui';
import { CommonModule } from '@angular/common';
import { AppLayoutComponent } from '../../../../layout/app-layout/app-layout.component';
import {
  CORE_SCHEMA_ID,
  GROUP_AEB_CUSTOMS_SCHEMA_ID,
} from '../../../../models/business/scim-schemas/scim-schema';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Subscription,
} from 'rxjs';
import { FormCommonAttributesComponent } from '../../../../shared/forms/form-common-attributes/form-common-attributes.component';
import { FormGroupAebCustomsComponent } from '../../../../shared/forms/form-group-aeb-customs/form-group-aeb-customs.component';
import { HumanUserGETType } from '../../../../models/business/human-user';
import {
  AsyncData,
  withAsyncData,
} from '../../../../shared/rxjs-operators/with-async-data';
import { MessageSearchSomethingComponent } from './components/message-search-something/message-search-something.component';
import { MessageSearchNotFoundComponent } from './components/message-search-not-found/message-search-not-found.component';
import { asCsspCustomerIdentificationAttribute } from '../../../../models/business/scim-schemas/scim_schema__group-aeb-customs';
import {
  AsyncAction,
  withAsyncAction,
} from '../../../../shared/rxjs-operators/with-async-action';
import { ActivatedRoute, Router } from '@angular/router';

type AsyncActions = 'searching' | 'updating';

@Component({
  selector: 'page-human-account-search',
  standalone: true,
  templateUrl: './human-account-search.page.html',
  styleUrls: ['./human-account-search.page.scss'],
  imports: [
    CommonModule,
    AppLayoutComponent,
    ReactiveFormsModule,
    FiltersComponent,
    SearchInputComponent,
    FilterSingleSelectComponent,
    FilterSingleSelectOptionComponent,
    FilterResultsComponent,
    AppLayoutComponent,
    ButtonComponent,
    FormCommonAttributesComponent,
    FormGroupAebCustomsComponent,
    MessageSearchSomethingComponent,
    MessageSearchNotFoundComponent,
  ],
})
export class HumanAccountSearchPage implements OnInit, AfterViewInit {
  @ViewChildren(FormCommonAttributesComponent)
  formCommonAttributesComponents: QueryList<FormCommonAttributesComponent>;
  @ViewChildren(FormGroupAebCustomsComponent)
  formGroupAebCustomsComponents: QueryList<FormGroupAebCustomsComponent>;

  public searchForm = new FormGroup({
    searchTerm: new CorePlatformUiFilterFormControl('Search Term', ''),
    searchBy: new CorePlatformUiFilterFormControl('Search By', 'Human Account'),
  });

  schemasForms = new FormGroup({
    welcomeEmail: new FormControl(true),
    groupAEBCustoms: new FormControl(true),
  });

  asyncAction = new AsyncAction<AsyncActions>('searching');
  asyncHumanUser = new AsyncData<HumanUserGETType>();

  hasFormChanges = new BehaviorSubject(false);

  whatToShow: 'message-search-something' | 'message-search-not-found' | 'form' =
    'message-search-something';

  constructor(
    private humanUsersService: HumanAccountService,
    private changeDetector: ChangeDetectorRef,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router, // Inject Router for updating query params
  ) {}

  ngOnInit() {
    this.trackGetHumanUsersAsync();
    this.SyncQueryParamsAndForm();
    this.keepWhatToShowUpdated();
  }

  ngAfterViewInit(): void {
    this.trackFormsChanges();
  }

  onSubmitSearch() {
    this.humanUsersService
      .getUserData(this.searchForm.get('searchTerm')?.value)
      .pipe(
        withAsyncAction(this.asyncAction, 'searching'),
        withAsyncData(this.asyncHumanUser, (data) => data.resources[0]),
      )
      .subscribe();
  }

  private responseToCommonAttributes(humanUser: HumanUserGETType) {
    if (!this.formCommonAttributesComponents.first) return;
    const form = this.formCommonAttributesComponents.first.commonAttributesForm;

    form.setValuesAndResetInitial({
      firstName: humanUser.name.givenName,
      lastName: humanUser.name.familyName,
      active: humanUser.active,
      username: humanUser.userName,
    });
    this.changeDetector.detectChanges();
  }

  private responseToAppRelatedAttributes(response: HumanUserGETType) {
    const form = this.formGroupAebCustomsComponents.first?.groupAEBCustomsForm;
    const groupAEBCustomsProperties = response[GROUP_AEB_CUSTOMS_SCHEMA_ID];

    if (!form) return;
    if (!groupAEBCustomsProperties) return;

    form.setValuesAndResetInitial({
      Tenant: groupAEBCustomsProperties.Tenant,
      LocationCode: groupAEBCustomsProperties.LocationCode,
      EnterpriseId: groupAEBCustomsProperties.EnterpriseId ?? null,
      CustomerId: groupAEBCustomsProperties.CustomerId ?? null,
      ContactId: groupAEBCustomsProperties.ContactId ?? null,
      Company: groupAEBCustomsProperties.Company ?? null,
      CsspCustomerIdentificationAttribute:
        groupAEBCustomsProperties.CsspCustomerIdentificationAttribute,
    });
  }

  private trackGetHumanUsersAsync() {
    this.asyncHumanUser.onChanges$.subscribe(([state, data]) => {
      if (state === 'success-with-data') {
        setTimeout(() => {
          if (!data) return;
          this.responseToCommonAttributes(data);
          this.responseToAppRelatedAttributes(data);
        });
      }
    });
  }

  private trackFormsChanges() {
    let subscription: Subscription | null = null;

    combineLatest([
      this.formCommonAttributesComponents.changes,
      this.formGroupAebCustomsComponents.changes,
    ])
      .pipe(
        map(
          () =>
            [
              this.formCommonAttributesComponents.first,
              this.formGroupAebCustomsComponents.first,
            ] as const,
        ),
        filter(([component1, component2]) => !!component1 && !!component2),
      )
      .subscribe(
        ([formCommonAttributesComponent, formGroupAebCustomsComponent]) => {
          if (subscription) subscription.unsubscribe();

          subscription = combineLatest([
            formCommonAttributesComponent.commonAttributesForm.hasChanged$,
            formGroupAebCustomsComponent.groupAEBCustomsForm.hasChanged$,
          ]).subscribe(([hasChanged1, hasChanged2]) => {
            this.hasFormChanges.next(hasChanged1 || hasChanged2);
          });
        },
      );
  }

  onUpdate() {
    if (!this.formCommonAttributesComponents.first) return;
    if (!this.formGroupAebCustomsComponents.first) return;

    const commonAttributesData =
      this.formCommonAttributesComponents.first.commonAttributesForm
        .currRawValues;
    const groupAEBCustomsData =
      this.formGroupAebCustomsComponents.first.groupAEBCustomsForm
        .currRawValues;

    const payload = {
      active: commonAttributesData.active ?? undefined,
      userName: commonAttributesData.username ?? undefined,
      addresses: [],
      phoneNumbers: [],
      emails: [
        {
          value: `${commonAttributesData.username}`,
          type: 'default',
        },
      ],
      name: {
        givenName: `${commonAttributesData.firstName}`,
        familyName: `${commonAttributesData.lastName}`,
        formatted: `${commonAttributesData.firstName} ${commonAttributesData.lastName}`,
      },
      schemas: [CORE_SCHEMA_ID, GROUP_AEB_CUSTOMS_SCHEMA_ID],
      [GROUP_AEB_CUSTOMS_SCHEMA_ID]: {
        Tenant: groupAEBCustomsData.Tenant ?? undefined,
        LocationCode: groupAEBCustomsData.LocationCode ?? undefined,
        EnterpriseId: groupAEBCustomsData.EnterpriseId ?? undefined,
        CustomerId: groupAEBCustomsData.CustomerId ?? undefined,
        ContactId: groupAEBCustomsData.ContactId ?? undefined,
        Company: groupAEBCustomsData.Company ?? undefined,
        CsspCustomerIdentificationAttribute:
          asCsspCustomerIdentificationAttribute(
            groupAEBCustomsData.CsspCustomerIdentificationAttribute,
          ),
      },
    };

    const id = this.asyncHumanUser.data$.value?.id || '';

    this.humanUsersService
      .updateUser(id, payload)
      .pipe(
        withAsyncAction(this.asyncAction, 'updating'),
        withAsyncData(this.asyncHumanUser, (data) => data),
      )
      .subscribe({
        next: () => {
          this.toastService.show({
            message: 'Human Account updated successfully',
            type: 'success',
          });
        },
      });
  }

  private keepWhatToShowUpdated() {
    combineLatest([
      this.asyncAction.onChanges$(),
      this.asyncHumanUser.onChanges$,
    ]).subscribe(([[actionName, actionState], [dataState, dataData]]) => {
      if (
        (actionName === 'searching' &&
          actionState === 'success' &&
          dataState === 'success-with-data') ||
        actionName === 'updating'
      ) {
        this.whatToShow = 'form';
      } else if (
        actionName === 'searching' &&
        dataState === 'not-initialized'
      ) {
        this.whatToShow = 'message-search-something';
      } else if (
        actionName === 'searching' &&
        dataState === 'success-without-data'
      ) {
        this.whatToShow = 'message-search-not-found';
      }
    });
  }

  private SyncQueryParamsAndForm() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const searchTerm = params['searchTerm'];
      if (searchTerm && searchTerm !== this.searchForm.value.searchTerm) {
        this.searchForm.controls.searchTerm.setValue(searchTerm, {
          emitEvent: false,
        });
        this.onSubmitSearch();
      }
    });

    this.searchForm.controls.searchTerm.valueChanges.subscribe((value) => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { searchTerm: value },
        queryParamsHandling: 'merge',
      });
    });
  }
}
