<gls-card [interactive]="false">
  <ng-template glsTemplate="header">
    <div class="card-header" [formGroup]="schemasForms">
      <h4 class="card-header-title">Group AEB Customs</h4>
      <gls-checkbox formControlName="groupAEBCustoms" [disabled]="true">
      </gls-checkbox>
    </div>
  </ng-template>
  <ng-template glsTemplate="content">
    <div
      class="form-container"
      [formGroup]="groupAEBCustomsForm.form"
      *ngIf="schemasForms.controls['groupAEBCustoms'].value"
    >
      <div class="right">
        <div class="two-columns">
          <gls-combobox
            formControlName="Tenant"
            placeholder="Select Tenant"
            [loading]="false"
            [multiple]="false"
          >
            <ng-template glsTemplate="labelSlot">Tenant</ng-template>
            <gls-combobox-option
              *ngFor="let country of COUNTRIES"
              [ngValue]="country.code"
              [label]="country.code + ' - ' + country.label"
            ></gls-combobox-option>
          </gls-combobox>
          <gls-text-input formControlName="LocationCode" autocomplete="off">
            <ng-template glsTemplate="labelSlot"> Location Code </ng-template>
          </gls-text-input>
        </div>
        <div class="two-columns">
          <gls-text-input formControlName="EnterpriseId" autocomplete="off">
            <ng-template glsTemplate="labelSlot">Enterprise Id</ng-template>
          </gls-text-input>
          <gls-text-input formControlName="CustomerId" autocomplete="off">
            <ng-template glsTemplate="labelSlot">Customer Id</ng-template>
          </gls-text-input>
        </div>
        <div class="two-columns">
          <gls-text-input formControlName="ContactId" autocomplete="off">
            <ng-template glsTemplate="labelSlot">Contact Id</ng-template>
          </gls-text-input>
          <gls-text-input formControlName="Company" autocomplete="off">
            <ng-template glsTemplate="labelSlot">Company</ng-template>
          </gls-text-input>
        </div>
        <div class="two-columns">
          <gls-combobox
            formControlName="CsspCustomerIdentificationAttribute"
            placeholder="Select an option"
            [loading]="false"
            [multiple]="false"
          >
            <ng-template glsTemplate="labelSlot"
              >Identification Type</ng-template
            >
            <gls-combobox-option [ngValue]="'contactId'" label="Contact Id" />
            <gls-combobox-option [ngValue]="'customerId'" label="Customer Id" />
            <gls-combobox-option
              [ngValue]="'enterpriseId'"
              label="Enterprise Id"
            />
          </gls-combobox>
          <div></div>
        </div>
      </div>
    </div>
  </ng-template>
</gls-card>
