import { Component, Inject } from '@angular/core';
import {
  MODAL_DATA,
  ModalContainerComponent,
} from '../../services/modal/modal-container/modal-container.component';
import {
  AlertComponent,
  ButtonComponent,
  GLSTemplateDirective,
  ModalComponent,
} from '@gls/platform-ui';
import { ErrorHeadingPipe } from '../../pipes/error-heading.pipe';
import { z } from 'zod';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal-error-client',
  standalone: true,
  imports: [
    CommonModule,
    AlertComponent,
    ModalComponent,
    GLSTemplateDirective,
    ButtonComponent,
    ErrorHeadingPipe,
  ],
  templateUrl: './modal-error-client.component.html',
  styleUrl: './modal-error-client.component.scss',
})
export class ModalErrorClientComponent {
  error: any;
  isZodError = false;

  constructor(
    private _modalContainer: ModalContainerComponent<ModalErrorClientComponent>,
    @Inject(MODAL_DATA) public data: { error: Error },
  ) {
    this.error = data.error;

    if (this.error instanceof z.ZodError) {
      this.isZodError = true;
    }
  }

  public close(): void {
    this._modalContainer.destroy();
  }
}
