import { Pipe, PipeTransform } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BackendErrorSchema } from '../../models/error/backend-http-error-response';
import { z } from 'zod';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'errorDetails',
  standalone: true,
})
export class ErrorDetailsPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: unknown): unknown {
    if (value instanceof HttpErrorResponse) {
      const parse = BackendErrorSchema.safeParse(value.error);
      if (!parse.success) return value;
      const error = parse.data.error;

      return `${this.capitalizeFirstLetter(error.detail)}.`;
    }

    if (value instanceof z.ZodError) {
      return value.errors.map((issue) => this.formatIssue(issue)).join('\n');
    }

    return value;
  }

  private capitalizeFirstLetter(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private formatIssue(issue: z.ZodIssue): string {
    const path = issue.path.length > 0 ? issue.path.join('.') : 'root'; // Join path to identify the field
    const baseMessage = `${this.capitalizeFirstLetter(path)}: ${issue.message}`;

    // If additional details about expected/received are available, append them
    const expected = (issue as any).expected;
    const received = (issue as any).received;

    if (expected !== undefined && received !== undefined) {
      return `${baseMessage} (expected: ${expected}, received: ${received})`;
    }

    return baseMessage; // Default message if no expected/received info
  }
}
