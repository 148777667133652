import { pipe, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { z, ZodError } from 'zod';

// TODO Work in progress

export const validateWithZod = <T>(schema: z.Schema<T>) => {
  return pipe(
    map((data: unknown) => {
      const result = schema.safeParse(data);

      if (!result.success) throw result.error;
      return result.data;
    }),
    catchError((error) => {
      if (error instanceof ZodError) {
        console.error('handle zod error: ', error);
      }

      return throwError(() => error);
    }),
  );
};
