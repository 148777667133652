import { FormGroup } from '@angular/forms';
import { BehaviorSubject, filter, map, pairwise, startWith } from 'rxjs';
import { deepEqual } from './functions/deep-equal';

export class TrackedForm<TFormGroup extends FormGroup<any>> {
  public form: TFormGroup;

  private _initRawValues: ReturnType<TFormGroup['getRawValue']>;
  private _prevRawValues: ReturnType<TFormGroup['getRawValue']>;
  private _currRawValues$: BehaviorSubject<
    ReturnType<TFormGroup['getRawValue']>
  >;

  private _hasChanged$ = new BehaviorSubject(false);

  constructor(form: TFormGroup) {
    this.form = form;

    this._initRawValues = this.form.getRawValue();
    this._prevRawValues = this.form.getRawValue();
    this._currRawValues$ = new BehaviorSubject(this.form.getRawValue());

    this.trackValueChanges();
  }

  public get initRawValues() {
    return this._initRawValues;
  }

  public get prevRawValues() {
    return this._prevRawValues;
  }

  public get currRawValues() {
    return this._currRawValues$.value;
  }

  public patchValue(value: ReturnType<TFormGroup['getRawValue']>) {
    this.form.patchValue(value);
  }

  public get hasChanged() {
    return this._hasChanged$.value;
  }

  public get hasChanged$() {
    return this._hasChanged$.asObservable();
  }

  public get onChanged$() {
    return this._hasChanged$.pipe(
      startWith(false),
      pairwise(),
      filter(([prev, curr]) => !prev && curr),
      map(([prev, curr]) => curr),
    );
  }

  public setValuesAndResetInitial(
    values: ReturnType<TFormGroup['getRawValue']>,
  ) {
    this._initRawValues = values;
    this._prevRawValues = values;
    this._currRawValues$.next(values);
    this.form.setValue(values);
  }

  public resetToInitialValues() {
    this.form.setValue(this._initRawValues);
  }

  private trackValueChanges() {
    this.form.valueChanges
      .pipe(
        startWith(this.initRawValues),
        map((value) => this.form.getRawValue()),
        pairwise(),
      )
      .subscribe(([prev, curr]) => {
        this._prevRawValues = prev;
        this._currRawValues$.next(curr);

        this._hasChanged$.next(
          !deepEqual(this._initRawValues, this._currRawValues$.value),
        );
      });
  }
}
