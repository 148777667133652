import { Pipe, PipeTransform } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { z } from 'zod';

@Pipe({
  name: 'errorHeading',
  standalone: true,
})
export class ErrorHeadingPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (value instanceof HttpErrorResponse) {
      return `${value.statusText} • ${value.status}`;
    }

    if (value instanceof z.ZodError) {
      return 'Data Validation Error';
    }

    return value;
  }
}
