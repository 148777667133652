import { z } from 'zod';

export const CORE_SCHEMA_ID = 'urn:ietf:params:scim:schemas:core:2.0:User';
export const WELCOME_EMAIL_SCHEMA_ID =
  'urn:ietf:params:scim:schemas:extension:welcome_email:2.0:User';
export const GROUP_AEB_CUSTOMS_SCHEMA_ID =
  'urn:ietf:params:scim:schemas:extension:group_aeb_customs:2.0:User';

export const SCIMSchema = z.union([
  z.enum([
    CORE_SCHEMA_ID,
    WELCOME_EMAIL_SCHEMA_ID,
    GROUP_AEB_CUSTOMS_SCHEMA_ID,
  ]),
  z.string(),
]);

export type SCIMSchemaType = z.infer<typeof SCIMSchema>;
