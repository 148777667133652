import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  HostListener,
  InjectionToken,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from '../modal.service';

export const MODAL_DATA = new InjectionToken<any>('ModalData');

export class ModalOptions {
  hasBackdrop?: boolean = true;
  data?: any = {};
}

@Component({
  selector: 'app-modal-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-container.component.html',
  styleUrl: './modal-container.component.scss',
})
export class ModalContainerComponent<T> implements OnInit, OnDestroy {
  private _options: ModalOptions = new ModalOptions();
  private _dismissed = new EventEmitter();

  childComponentRef?: ComponentRef<T>;
  selfComponentRef?: ComponentRef<ModalContainerComponent<T>>;
  childTemplateRef?: TemplateRef<any>;
  @ViewChild('viewContainerRef', { read: ViewContainerRef })
  viewContainerRef?: ViewContainerRef;

  constructor(
    private _modalService: ModalService,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _appRef: ApplicationRef,
  ) {}

  ngOnInit(): void {}

  get dismissed() {
    return this._dismissed.asObservable();
  }

  get instance() {
    return this.childComponentRef?.instance;
  }

  attachComponent(component: Type<T>, options: ModalOptions) {
    this._options = options;

    const componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(component);
    this.childComponentRef = this.viewContainerRef?.createComponent<T>(
      componentFactory,
      undefined,
      this.createInjector(),
    );
  }

  attachTemplate(templateRef: TemplateRef<any>, options: ModalOptions) {
    this._options = options;
    this.childTemplateRef = templateRef;
  }

  destroy(): void {
    if (this.selfComponentRef) {
      this._appRef.detachView(this.selfComponentRef.hostView);
      this.selfComponentRef.destroy();
    }

    this._dismissed.emit();
    this._dismissed.complete();
  }

  private createInjector() {
    return Injector.create({
      parent: this._injector,
      providers: [{ provide: MODAL_DATA, useValue: this._options.data }],
    });
  }

  @HostListener('click', ['$event'])
  private onClicked() {
    if (this._options.hasBackdrop) this.destroy();
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.viewContainerRef?.clear();
      this.viewContainerRef = undefined;
    }, 2500);
  }
}
