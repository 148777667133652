import { z } from 'zod';
import { HttpErrorResponse } from '@angular/common/http';

export const BackendErrorSchema = z.object({
  error: z.object({
    detail: z.string(),
    schemas: z.array(z.string()),
    scimType: z.string().optional(),
    status: z.string(),
  }),
});

export type BackendError = z.infer<typeof BackendErrorSchema>;

export class BackendHttpErrorResponse extends HttpErrorResponse {
  override error: BackendError;
}
