import { z } from 'zod';

export const APP_CONTEXT_GROUP_AEB_CUSTOMS = 'group_aeb_customs';

export const WelcomeEmailSchema = z.object({
  sendEmail: z.boolean(),
  appContext: z.enum([APP_CONTEXT_GROUP_AEB_CUSTOMS]),
});

export type WelcomeEmailType = z.infer<typeof WelcomeEmailSchema>;
