import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BreadcrumbItemComponent,
  BreadcrumbsComponent,
  DropdownComponent,
  DropdownItemComponent,
  DropdownItemLabelComponent,
  DropdownItemSeparatorComponent,
  IconButtonComponent,
  PageHeaderComponent,
  PopperDirective,
  PreventPopperCloseDirective,
} from '@gls/platform-ui';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatestWith } from 'rxjs';
import { RouteTrackerService } from '../../../../services/client-state/route-tracker.service';
import { snakeCaseToTitleCase } from '../../../../shared/functions/snakecase-to-titlecase/snakecase-to-titlecase';

type UrlSegment = {
  label: string;
  url: string;
};

@Component({
  selector: 'app-panel-top',
  standalone: true,
  imports: [
    CommonModule,
    BreadcrumbItemComponent,
    BreadcrumbsComponent,
    DropdownComponent,
    PageHeaderComponent,
    IconButtonComponent,
    PopperDirective,
    DropdownItemLabelComponent,
    DropdownItemComponent,
    DropdownItemSeparatorComponent,
    PreventPopperCloseDirective,
  ],
  templateUrl: './panel-top.component.html',
  styleUrls: ['./panel-top.component.scss'],
})
export class PanelTopComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  private routeTrackerService = inject(RouteTrackerService);

  pageTitle = '';
  urlSegments: Array<UrlSegment> = [];

  public ngOnInit(): void {
    this.trackURLChanges();
  }

  private trackURLChanges(): void {
    this.routeTrackerService.url$
      .pipe(
        combineLatestWith(this.routeTrackerService.paramMap$),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([url]) => {
        const [urlWithoutQuery] = url.split('?');
        let segmentPath = '';

        this.urlSegments = urlWithoutQuery
          .split('/')
          .filter((part) => part.length > 0)
          .map((segment) => {
            segmentPath += `/${segment}`;
            return {
              label: this.sanitizeSegment(segment),
              url: segmentPath,
            };
          });

        this.pageTitle = this.urlSegments.at(-1)?.label ?? '';
        this.urlSegments = this.urlSegments.slice(0, -1);
      });
  }

  private sanitizeSegment(segment: string): string {
    if (this.isAParamValue(segment)) return segment;
    return snakeCaseToTitleCase(segment).replace('Api', 'API');
  }

  private isAParamValue(searchTerm: string): boolean {
    const paramMap = this.routeTrackerService.paramMap;
    if (paramMap === null || searchTerm === '') return false;

    let found = false;
    for (const key of paramMap.keys) {
      if (paramMap.get(key) === searchTerm) {
        found = true;
        break;
      }
    }

    return found;
  }
}
