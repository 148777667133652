import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  defer,
  distinctUntilChanged,
  map,
  Observable,
  pairwise,
  startWith,
  throwError,
} from 'rxjs';

export type DataState =
  | 'not-initialized'
  | 'loading'
  | 'loading-first-time'
  | 'updating'
  | 'success-with-data'
  | 'success-without-data'
  | 'error';

export class AsyncData<T> {
  state$ = new BehaviorSubject<DataState>('not-initialized');
  prevState$ = new BehaviorSubject<DataState>('not-initialized');
  data$ = new BehaviorSubject<T | null>(null);

  constructor() {
    this.state$
      .pipe(
        startWith(<DataState>'not-initialized'),
        pairwise(),
        map(([prevState]) => prevState),
      )
      .subscribe((prevState) => this.prevState$.next(prevState));
  }

  onChanges$: Observable<[DataState, T | null]> = this.state$
    .pipe(startWith(<DataState>'not-initialized'), pairwise())
    .pipe(combineLatestWith(this.data$.pipe(startWith(null), pairwise())))
    .pipe(
      distinctUntilChanged(
        ([prevState, prevData], [currState, currData]) =>
          prevState[1] === currState[1],
      ),
      map(([[, currState], [, currData]]) => {
        return [currState, currData];
      }),
    );
}

export function withAsyncData<T, U>(
  asyncDataProperty: AsyncData<U>,
  adaptFn: (sourceData: T) => U,
) {
  return (source$: Observable<T>): Observable<T> => {
    return defer(() => {
      const currentState = asyncDataProperty.state$.value;

      if (currentState === 'not-initialized') {
        asyncDataProperty.state$.next('loading-first-time');
      } else if (currentState === 'success-with-data') {
        asyncDataProperty.state$.next('updating');
      } else {
        asyncDataProperty.state$.next('loading');
      }

      return source$.pipe(
        // When data is emitted, use adaptFn to transform and set the success state
        map((data: T) => {
          const adaptedData = adaptFn(data);

          asyncDataProperty.data$.next(
            adaptedData === undefined ? null : adaptedData,
          );

          if (adaptedData != null) {
            asyncDataProperty.state$.next('success-with-data');
          } else {
            asyncDataProperty.state$.next('success-without-data');
          }

          return data; // Pass the original data downstream
        }),

        // Handle any error by setting the error state
        catchError((error) => {
          asyncDataProperty.state$.next('error');
          // asyncDataProperty.data$.next(null);
          return throwError(() => error);
        }),
      );
    });
  };
}
