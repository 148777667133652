import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Renderer2,
  RendererFactory2,
  TemplateRef,
  Type,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  ModalContainerComponent,
  ModalOptions,
} from './modal-container/modal-container.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  showComponent<T>(
    component: Type<T>,
    options: ModalOptions = new ModalOptions(),
  ) {
    const modalContainerRef = this.createModalContainer<T>(undefined);
    modalContainerRef.instance.attachComponent(component, options);

    return modalContainerRef.instance;
  }

  showTemplateRef(
    templateRef: TemplateRef<any>,
    options: ModalOptions = new ModalOptions(),
  ) {
    const modalContainerRef = this.createModalContainer(templateRef);
    modalContainerRef.instance.attachTemplate(templateRef, options);

    return modalContainerRef.instance;
  }

  private createModalContainer<T>(
    childRef: ComponentRef<T> | TemplateRef<any> | undefined,
  ) {
    // 1. Create a component reference from the component
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory<
        ModalContainerComponent<T>
      >(ModalContainerComponent);
    const componentRef = componentFactory.create(this.injector);

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // 4. Append DOM element to the body
    this.renderer.appendChild(this.document.body, domElem);
    componentRef.changeDetectorRef.detectChanges();

    componentRef.instance.selfComponentRef = componentRef;

    return componentRef;
  }
}
