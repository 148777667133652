import { z } from 'zod';

// Define the Zod schema for the Country type
export const CountrySchema = z.object({
  label: z.string(),
  code: z.string(),
});

// Define the schema for the array of countries
export const CountriesSchema = z.array(CountrySchema);

// Example usage with the COUNTRIES constant
export const COUNTRIES = CountriesSchema.parse(
  [
    { label: 'Germany', code: 'DE' },
    { label: 'Belgium', code: 'BE' },
    { label: 'Finland', code: 'FI' },
    { label: 'Portugal', code: 'PT' },
    { label: 'Bulgaria', code: 'BG' },
    { label: 'Denmark', code: 'DK' },
    { label: 'Lithuania', code: 'LT' },
    { label: 'Luxembourg', code: 'LU' },
    { label: 'Croatia', code: 'HR' },
    { label: 'Latvia', code: 'LV' },
    { label: 'France', code: 'FR' },
    { label: 'Hungary', code: 'HU' },
    { label: 'Sweden', code: 'SE' },
    { label: 'Slovenia', code: 'SI' },
    { label: 'Slovakia', code: 'SK' },
    { label: 'United Kingdom', code: 'GB' },
    { label: 'Ireland', code: 'IE' },
    { label: 'Estonia', code: 'EE' },
    { label: 'Switzerland', code: 'CH' },
    { label: 'Greece', code: 'GR' },
    { label: 'Italy', code: 'IT' },
    { label: 'Spain', code: 'ES' },
    { label: 'Austria', code: 'AT' },
    { label: 'Czech Republic', code: 'CZ' },
    { label: 'Poland', code: 'PL' },
    { label: 'Romania', code: 'RO' },
    { label: 'Netherlands', code: 'NL' },
    { label: 'Serbia', code: 'RS' },
    { label: 'United States', code: 'US' },
    { label: 'Canada', code: 'CA' },
    { label: 'Malta', code: 'MT' },
    { label: 'Norway', code: 'NO' },
    { label: 'Israel', code: 'IL' },
    { label: 'Nepal', code: 'NP' },
    { label: 'Turkey', code: 'TR' },
  ].sort((a, b) => a.code.localeCompare(b.code)),
);
