import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import {
  HumanUserGETSchema,
  HumanUserPOSTSchema,
  HumanUserPUTSchema,
  HumanUserType,
} from '../../models/business/human-user';
import { PageSchema, PageType } from '../../models/http/page';
import { validateWithZod } from '../../shared/rxjs-operators/validate-schema';

@Injectable({
  providedIn: 'root',
})
export class HumanAccountService {
  private baseURL = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getUserData(email: string): Observable<PageType<typeof HumanUserGETSchema>> {
    const url = `${this.baseURL}/usermanagement/external/scim/v2/Users?filter=userName eq "${email}"`;

    return this.http
      .get(url)
      .pipe(validateWithZod(PageSchema(HumanUserGETSchema)));
  }

  createUser(payload: HumanUserType) {
    const url = `${this.baseURL}/usermanagement/external/scim/v2/Users`;
    return this.http
      .post(url, payload)
      .pipe(validateWithZod(HumanUserPOSTSchema));
  }

  // TODO payload type
  updateUser(id: string, payload: any) {
    const url = `${this.baseURL}/usermanagement/external/scim/v2/Users/${id}`;
    return this.http
      .put(url, payload)
      .pipe(validateWithZod(HumanUserPUTSchema));
  }
}
