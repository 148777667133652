import { HumanUserSchema, HumanUserType } from './human-user';
import {
  CORE_SCHEMA_ID,
  GROUP_AEB_CUSTOMS_SCHEMA_ID,
  WELCOME_EMAIL_SCHEMA_ID,
} from './scim-schemas/scim-schema';

export const validateCoreSCIMSchema = (data: unknown): boolean => {
  return (
    typeof data === 'object' &&
    data !== null &&
    Array.isArray((data as { schemas?: unknown }).schemas) &&
    (data as { schemas: string[] }).schemas.includes(CORE_SCHEMA_ID)
  );
};

export const messageCoreSCIMSchema = {
  message: `The 'schemas' array must always contain '${CORE_SCHEMA_ID}'.`,
};

export const validateWelcomeEmailSchema = (data: unknown): boolean => {
  return (
    typeof data === 'object' &&
    data !== null &&
    (!(data as Record<string, unknown>)[WELCOME_EMAIL_SCHEMA_ID] ||
      (Array.isArray((data as { schemas?: unknown }).schemas) &&
        (data as { schemas: string[] }).schemas.includes(
          WELCOME_EMAIL_SCHEMA_ID,
        )))
  );
};

export const messageWelcomeEmailSchema = {
  message: `The property ${WELCOME_EMAIL_SCHEMA_ID} must only exist if it is listed in the schemas array.`,
};

export const validateGroupAEBCustomsSchema = (data: unknown): boolean => {
  return (
    typeof data === 'object' &&
    data !== null &&
    (!(data as Record<string, unknown>)[GROUP_AEB_CUSTOMS_SCHEMA_ID] ||
      (Array.isArray((data as { schemas?: unknown }).schemas) &&
        (data as { schemas: string[] }).schemas.includes(
          GROUP_AEB_CUSTOMS_SCHEMA_ID,
        )))
  );
};

export const messageGroupAEBCustomsSchema = {
  message: `The property ${GROUP_AEB_CUSTOMS_SCHEMA_ID} must only exist if it is listed in the schemas array.`,
};

export function isHumanUserType(data: unknown): data is HumanUserType {
  const parsed = HumanUserSchema.safeParse(data);
  return parsed.success;
}
