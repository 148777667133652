import { Component } from '@angular/core';
import { AlertComponent } from '@gls/platform-ui';

@Component({
  selector: 'app-message-search-not-found',
  standalone: true,
  imports: [AlertComponent],
  templateUrl: './message-search-not-found.component.html',
  styleUrl: './message-search-not-found.component.scss',
})
export class MessageSearchNotFoundComponent {}
