<gls-modal (hideModal)="close()">
  <ng-template glsTemplate="header">
    <div class="d-flex jc-space-between">
      <span>Client Error</span>
    </div>
  </ng-template>
  <ng-template glsTemplate="main">
    <gls-alert color="error" variant="standard" [closeable]="false">
      <span alert-text>
        <ng-container *ngIf="isZodError">
          <span class="text-subheading">{{ error | errorHeading }}</span>
          <ul class="text-body">
            <li *ngFor="let item of error.errors">
              {{ item.path.join(".") }}: {{ item.message }}
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="!isZodError"> {{ error }} </ng-container>
      </span>
    </gls-alert>
  </ng-template>
  <ng-template glsTemplate="actions">
    <div
      class="d-flex"
      style="justify-content: end; gap: 1rem; flex-wrap: wrap"
    >
      <gls-button
        [disabled]="false"
        [destructive]="false"
        [loading]="false"
        variant="primary-exceptional"
        (click)="close()"
      >
        Close
      </gls-button>
    </div>
  </ng-template>
</gls-modal>
