import {
  BehaviorSubject,
  catchError,
  combineLatest,
  defer,
  distinctUntilChanged,
  map,
  Observable,
  throwError,
} from 'rxjs';

export type ActionState = 'not-started' | 'performing' | 'success' | 'error';
export type ExtendedAction<A> = A | 'none';

export class AsyncAction<A> {
  _name$: BehaviorSubject<ExtendedAction<A>>;
  _state$ = new BehaviorSubject<ActionState>('not-started');

  constructor(initialActionName: ExtendedAction<A>) {
    this._name$ = new BehaviorSubject(initialActionName);
  }

  public get name$(): Observable<ExtendedAction<A>> {
    return this._name$.pipe(
      distinctUntilChanged((prevName, currName) => prevName === currName),
    );
  }

  public get state$(): Observable<ActionState> {
    return this._state$.pipe(
      distinctUntilChanged((prevState, currState) => prevState === currState),
    );
  }

  public get name(): ExtendedAction<A> {
    return this._name$.value;
  }

  public get state(): ActionState {
    return this._state$.value;
  }

  public get nameAndState(): [ExtendedAction<A>, ActionState] {
    return [this.name, this.state];
  }

  public is(name: A | '*', state: ActionState | '*' = '*'): boolean {
    if (name === '*' && state === '*') return true;
    if (name === '*') return this.state === state;
    if (state === '*') return this.name === name;

    return this.name === name && this.state === state;
  }

  public isAny(names: A[], state: ActionState | '*' = '*'): boolean {
    return names.some((name) => this.is(name, state));
  }

  public onChanges$(): Observable<[ExtendedAction<A>, ActionState]> {
    return combineLatest([this.name$, this.state$]);
  }
}

export function withAsyncAction<A, T>(asyncAction: AsyncAction<A>, name: A) {
  return (source$: Observable<T>): Observable<T> => {
    return defer(() => {
      asyncAction._name$.next(name);
      asyncAction._state$.next('performing');

      return source$.pipe(
        map((data: T) => {
          asyncAction._state$.next('success');
          return data;
        }),

        // Handle any error by setting the error state
        catchError((error) => {
          asyncAction._state$.next('error');
          return throwError(() => error);
        }),
      );
    });
  };
}
