<gls-card [interactive]="false">
  <ng-template glsTemplate="header">
    <div class="card-header">
      <h4 class="card-header-title">Common Attributes</h4>
    </div>
  </ng-template>
  <ng-template glsTemplate="content">
    <div [formGroup]="commonAttributesForm.form" class="form-container">
      <div class="right">
        <div class="two-columns">
          <gls-text-input formControlName="firstName" autocomplete="off">
            <ng-template glsTemplate="labelSlot"> First Name </ng-template>
          </gls-text-input>
          <gls-text-input formControlName="lastName" autocomplete="off">
            <ng-template glsTemplate="labelSlot"> Last Name </ng-template>
          </gls-text-input>
        </div>
        <div class="two-columns">
          <gls-text-input formControlName="username" autocomplete="off">
            <ng-template glsTemplate="labelSlot">
              Username / Email
            </ng-template>
          </gls-text-input>
          <gls-toggle-switch-multiple formControlName="active">
            <ng-template glsTemplate="labelSlot">
              <div class="text-label">Active</div>
            </ng-template>
            <ng-template glsTemplate="optionsSlot">
              <gls-toggle-switch-multiple-option [value]="true">
                Active
              </gls-toggle-switch-multiple-option>
              <gls-toggle-switch-multiple-option [value]="false">
                Inactive
              </gls-toggle-switch-multiple-option>
            </ng-template>
          </gls-toggle-switch-multiple>
        </div>
      </div>
    </div>
  </ng-template>
</gls-card>
