import { z } from 'zod';

export const CsspCustomerIdentificationAttributeSchema = z.enum([
  'customerId',
  'contactId',
  'enterpriseId',
]);

export type CsspCustomerIdentificationAttributeType = z.infer<
  typeof CsspCustomerIdentificationAttributeSchema
>;

export const GroupAEBCustomsSchema = z.object({
  Tenant: z.string(),
  LocationCode: z.string(),
  EnterpriseId: z.string().optional(),
  CustomerId: z.string().optional(),
  ContactId: z.string().optional(),
  Company: z.string().optional(),
  CsspCustomerIdentificationAttribute:
    CsspCustomerIdentificationAttributeSchema,
});

export type GroupAEBCustomsType = z.infer<typeof GroupAEBCustomsSchema>;

export const GroupAEBCustomsFromBackendSchema = GroupAEBCustomsSchema.extend({
  CssCustomerId: z.string(),
});

export function asCsspCustomerIdentificationAttribute(
  data: unknown,
): CsspCustomerIdentificationAttributeType | undefined {
  const parsed = CsspCustomerIdentificationAttributeSchema.safeParse(data);
  return parsed.success ? parsed.data : undefined;
}
